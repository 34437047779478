<template>
    <div id="document_by_user_component" class="min-height-component-550">
        <datatable-filter-component :headers="headers" class="mt-4"/>

        <v-row class="ma-0 justify-space-between mb-4">
            <p class="font-weight-bold">
                {{ $t('document_by_user_component.total_documents', {total: my_files.length}) }}
            </p>
            <div>
                <v-btn class="square-btn-icon" color="secondary" outlined @click="downloadMyFile(null, $event)">
                    <v-icon color="secondary">mdi-download</v-icon>
                    <p v-if="selected_files.length > 0" class="secondary--text font-weight-bold mb-0 ml-2">
                        {{ selected_files.length }} {{ $t('document_by_user_component.selections') }}
                    </p>
                </v-btn>

                <v-btn v-if="selected_files.length > 0" class="ml-2 square-btn-icon" color="secondary" outlined
                       @click="dialog_remove_file = true;"
                >
                    <v-icon color="secondary">mdi-trash-can</v-icon>
                </v-btn>
            </div>
        </v-row>

        <!-- START PREVIEW DOCUMENT -->
        <v-dialog v-model="dialog_preview_pdf" max-width="700" scrollable>
            <v-card>
                <v-card-text class="pa-1">
                    <pdf
                        :src="selected_file.path_file"
                        :page="index_page_to_display"
                    />
                    <v-progress-circular indeterminate color="primary" v-if="loading_my_file_pdf" class="pdf-loader"/>
                </v-card-text>

                <v-divider/>
                <v-card-actions class="d-flex justify-space-between">
                    <v-icon @click="1 < index_page_to_display ? index_page_to_display-- : null">mdi-chevron-left</v-icon>
                    <v-icon @click="total_pages > index_page_to_display ? index_page_to_display++ : null">mdi-chevron-right</v-icon>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_preview_picture" max-width="700">
            <v-card min-height="20%">
                <v-card-text class="pa-1">
                    <v-img :src="selected_file.path_file">
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular indeterminate color="primary"/>
                            </v-row>
                        </template>
                    </v-img>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- END PREVIEW DOCUMENT -->

        <v-data-table
            v-model="selected_files"
            :headers="headers"
            :items="my_files"
            :loading="loading_my_files"
            :search="search"
            checkbox-color="primary"
            class="stripe-date-table select-data-table"
            disable-sort
            item-key="id_file"
            show-select
        >
            <template v-slot:item.date_creation="{ item }">
                <span>{{ moment(item.date_creation).format(date_format) }}</span>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon class="mr-2" @click="prepareOpenDialogPreview(item);">mdi-eye</v-icon>
                <v-icon class="mr-2" @click="openDialogEditFile(item)">mdi-pencil</v-icon>
                <a class="text-decoration-none" @click="downloadMyFile(item, $event)">
                    <v-icon class="mr-2">mdi-download</v-icon>
                </a>
                <v-icon class="mr-2" @click="openDialogDeleteFile(item);">mdi-trash-can</v-icon>
            </template>
        </v-data-table>

        <!-- DIALOG ADD FILE -->
        <v-dialog v-model="dialog_add_file" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-uppercase font-weight-bold">
                        {{ $t('document_by_user_component.add_document') }}
                    </span>
                </v-card-title>

                <v-card-text class="pb-0">
                    <v-file-input class="mb-4" filled hide-details label="Ajouter un document" prepend-icon=""
                                  prepend-inner-icon="mdi-paperclip" v-model="new_file" @change="prepareUpload"
                                  accept="application/pdf, image/jpeg, image/png"
                    />

                    <v-text-field v-model="filename" class="mb-4" filled hide-details
                                  :label="$t('document_by_user_component.document_name')"/>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="closeAddDialog()">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>
                    <v-btn color="primary" class="btn-secondary-loader" :loading="loading_edit" text
                           :disabled="!valid" @click="addFile()"
                    >
                        <span :class="(valid ? 'secondary--text' : 'font-color-light') +  ' font-weight-bold'">
                            {{ $t('global.validate') }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- DIALOG EDIT FILE -->
        <v-dialog v-model="dialog_edit_file" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-uppercase font-weight-bold">
                        {{ $t('document_by_user_component.edit_document') }}
                    </span>
                </v-card-title>

                <v-card-text class="pb-2">
                    <v-text-field :label="$t('document_by_user_component.document_name')"
                                  class="mb-4" filled hide-details v-model="filename"
                    />
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog_edit_file = false">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>
                    <v-btn color="primary" class="btn-secondary-loader" :loading="loading_edit" text
                           @click="editFile()"
                    >
                        <span class="secondary--text font-weight-bold">{{ $t('global.update') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- DIALOG REMOVE FILE -->
        <v-dialog v-model="dialog_remove_file" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-uppercase font-weight-bold error--text">
                        {{
                            selected_files.length > 0 ? $t('document_by_user_component.delete_documents') :
                                $t('document_by_user_component.delete_document')
                        }}
                    </span>
                </v-card-title>

                <v-card-text class="pb-0">
                    <div>
                        <span v-if="selected_files.length > 0">
                            {{ $t('document_by_user_component.confirm_delete_documents') }}
                            <br>
                            <span v-for="selected_file in selected_files"
                                  :key="selected_file.id_file" class="font-weight-bold ml-2"
                            >
                                - {{ selected_file.filename }}<br>
                            </span>
                        </span>

                        <span v-else>
                            {{ $t('document_by_user_component.confirm_delete_document') }}
                            <span class="font-weight-bold"> {{ selected_file.filename }}</span>&thinsp;?
                        </span>
                    </div>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog_remove_file = false">
                        <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>
                    <v-btn color="error" text @click="removeFile()" :loading="loading_edit" class="btn-error-loader">
                        <span class="error--text font-weight-bold">{{ $t('global.validate') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-btn v-if="userHasAccess(this.module_designation, role_edit)" @click="dialog_add_file = true;" fab
               :class="is_mobile ? 'bottom-left-btn-mobile' : 'bottom-left-btn-desktop'" color="secondary" elevation="0"
        >
            <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
    </div>
</template>

<script>
import DatatableFilterComponent from "@/components/Global/DatatableFilterComponent";
import pdf from 'vue-pdf'
import FileRepository from "@/repositories/FileRepository";

export default {
    name: "DocumentByUserComponent",
    components: {DatatableFilterComponent, pdf},
    data() {
        return {
            module_designation: 'PWA_DOCUMENT',
            search: '',
            loading_my_files: false,
            my_files: [],
            headers: [
                {text: this.$t('document_by_user_component.document_name'), value: 'filename', filterable: true},
                {text: this.$t('document_by_user_component.date_publication'), value: 'date_creation', filterable: true},
                {text: this.$t('global.actions'), value: 'actions', filterable: true}
            ],

            dialog_add_file: false,
            dialog_edit_file: false,
            index_edit_file: null,
            id_file: null,
            new_file: null,
            filename: '',

            dialog_preview_picture: false,
            dialog_preview_pdf: false,
            loading_my_file_pdf: false,
            total_pages: 0,
            index_page_to_display: 1,

            dialog_remove_file: false,
            selected_files: [],
            selected_file: '',

            loading_edit: false,
            valid_file_format: 'application/pdf, image/png, image/jpeg'
        }
    },
    computed: {
        valid() {
            return this.filename !== '' && this.new_file && this.valid_file_format.includes(this.new_file.type);
        }
    },
    created() {
        this.getFilesBySpace();
    },
    methods: {
        getFilesBySpace() {
            this.loading_my_files = true;

            FileRepository.getByIdClientAndIdUserAndModuleDesignation(
                this.$store.getters['user_connected/user_connected'].id_client, this.module_designation
            ).then((success) => {
                this.my_files = success.data.data;
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_my_files = false;
            });
        },

        prepareUpload(file) {
            this.new_file = null;
            if (file) {
                if (this.checkFiles(file)) {
                    this.new_file = file;
                }
            }
        },

        checkFiles(file) {
            let valid = true;
            if (!this.valid_file_format.includes(file.type)) {
                valid = false;
                this.showSnackbar('error', this.$t('document_by_user_component.file_type_not_authorized'));
            }
            if (file.size > 10000000) {
                valid = false;
                this.showSnackbar('error', this.$t('document_by_user_component.size_exceed'));
            }

            return valid;
        },

        addFile() {
            this.loading_edit = true;
            let formData = new FormData();
            formData.append('file', this.new_file);
            formData.append('filename', this.filename);

            FileRepository.postUploadFile(this.module_designation, formData).then(() => {
                this.showSnackbar('success', this.$t('document_by_user_component.document_added'));
                this.getFilesBySpace();
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_edit = false;
                this.closeAddDialog();
            });
        },

        closeAddDialog() {
            this.new_file = {};
            this.filename = '';
            this.dialog_add_file = false;
        },

        openDialogEditFile(file) {
            this.dialog_edit_file = true;
            this.index_edit_file = this.my_files.indexOf(file);
            this.id_file = file.id_file;
            this.filename = file.filename;
        },

        editFile() {
            this.loading_edit = true;
            FileRepository.putEditFilename(this.id_file, this.module_designation, this.filename).then(() => {
                this.my_files[this.index_edit_file].filename = this.filename;
                this.showSnackbar('success', this.$t('document_by_user_component.file_modify'));
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_edit = false;
                this.id_file = null;
                this.filename = '';
                this.dialog_edit_file = false;
            });
        },

        openDialogDeleteFile(file) {
            this.id_file = file.id_file;
            this.selected_file = file;
            this.dialog_remove_file = true;
        },

        removeFile() {
            this.loading_edit = true;
            let fileToRemove = [];
            this.selected_files.forEach((selected_file) => {
                fileToRemove.push(selected_file.id_file);
            });

            if (this.selected_files.length === 0) {
                fileToRemove.push(this.id_file)
            }

            FileRepository.deleteFile({id_files: fileToRemove}, this.module_designation).then(() => {
                this.getFilesBySpace();
                this.showSnackbar('success', this.$t('document_by_user_component.file_deleted'));
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.dialog_remove_file = false;
                this.loading_edit = false;
            });
        },

        prepareOpenDialogPreview(file) {
            this.selected_file = file;
            if(file.file_type.type === "application/pdf") {
                this.openDialogPreviewPdf();
            } else {
                this.openDialogPreviewPicture();
            }
        },

        openDialogPreviewPicture() {
            this.dialog_preview_picture = true;
        },

        openDialogPreviewPdf() {
            this.index_page_to_display = 1;
            this.loading_my_file_pdf = true;
            this.dialog_preview_pdf = true;
            pdf.createLoadingTask(this.selected_file.path_file).promise.then(document => {
                this.total_pages = document.numPages;
                this.loading_my_file_pdf = false;
            });
        },

        downloadMyFile(item, event) {
            event.preventDefault();

            let data = [];
            if (this.selected_files.length > 0) {
                data = JSON.parse(JSON.stringify(this.selected_files));
            } else if (item) {
                data.push(item);
            } else {
                data = this.my_files;
            }

            if (data.length > 0) {
                data.forEach((file) => {
                    this.downloadFile(file, 'PWA_DOCUMENT');
                });
            }
        }
    }
}
</script>

<style>
.pdf-loader {
    position: absolute !important;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
}
</style>