<template>
    <div id="document-by-pro-component" class="min-height-component-550">
        <datatable-filter-component :headers="headers" class="mt-4"/>

        <v-row class="ma-0 justify-space-between mb-4">
            <p class="font-weight-bold">
                {{ $t('document_by_pro_component.document_name', {total: useful_documents.length}) }}
            </p>
            <div>
                <v-btn class="square-btn-icon" color="secondary" outlined
                       @click="downloadUsefulDocuments(null, $event)">
                    <v-icon color="secondary">mdi-download</v-icon>
                    <p v-if="selected_useful_documents.length > 0" class="secondary--text font-weight-bold mb-0 ml-2">
                        {{ selected_useful_documents.length }} {{ $t('document_by_pro_component.selections') }}
                    </p>
                </v-btn>
            </div>
        </v-row>

        <v-data-table
            v-model="selected_useful_documents"
            :headers="headers"
            :items="useful_documents"
            :loading="loading_useful_documents"
            :search="search"
            checkbox-color="primary"
            class="stripe-date-table select-data-table"
            disable-sort
            item-key="id_file"
            show-select
        >
            <template v-slot:item.date_creation="{ item }">
                <span>{{ moment(item.date_creation).format(date_format) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon class="mr-2" @click="prepareOpenDialogPreview(item);">mdi-eye</v-icon>
                <v-icon class="mr-2" @click="downloadUsefulDocuments(item, $event)">mdi-download</v-icon>
            </template>
        </v-data-table>

        <!-- START PREVIEW DOCUMENT -->
        <v-dialog v-model="dialog_preview_pdf" max-width="700" scrollable>
            <v-card>
                <v-card-text class="pa-1">
                    <pdf
                        :src="selected_file.path_file"
                        :page="index_page_to_display"
                    />
                    <v-progress-circular indeterminate color="primary" v-if="loading_my_file_pdf" class="pdf-loader"/>
                </v-card-text>

                <v-divider/>
                <v-card-actions class="d-flex justify-space-between">
                    <v-icon @click="1 < index_page_to_display ? index_page_to_display-- : null">mdi-chevron-left</v-icon>
                    <v-icon @click="total_pages > index_page_to_display ? index_page_to_display++ : null">mdi-chevron-right</v-icon>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_preview_picture" max-width="700">
            <v-card>
                <v-card-text class="pa-1">
                    <v-img :src="selected_file.path_file">
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular indeterminate color="primary"/>
                            </v-row>
                        </template>
                    </v-img>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- END PREVIEW DOCUMENT -->
    </div>
</template>

<script>
import DatatableFilterComponent from "@/components/Global/DatatableFilterComponent";
import FileRepository from "@/repositories/FileRepository";
import pdf from 'vue-pdf'

export default {
    name: "DocumentByProComponent",
    components: {DatatableFilterComponent, pdf},
    data() {
        return {
            search: '',
            useful_documents: [],
            loading_useful_documents: false,

            selected_useful_documents: [],
            headers: [
                {text: this.$t('document_by_pro_component.document_name'), value: 'filename', filterable: true},
                {text: this.$t('document_by_pro_component.publication_date'), value: 'date_creation', filterable: true},
                {text: this.$t('global.actions'), value: 'actions', filterable: true}
            ],

            selected_file: '',
            dialog_preview_picture: false,
            dialog_preview_pdf: false,
            loading_my_file_pdf: false,
            total_pages: 0,
            index_page_to_display: 1,
        }
    },
    created() {
        this.getUsefulDocuments()
    },
    methods: {
        getUsefulDocuments() {
            this.loading_useful_documents = true;

            FileRepository.getByIdProfessionalAndIdClientAndModuleDesignation(
                this.$store.getters['user_connected/user_connected'].id_professional, 'PWA_DOCUMENT'
            ).then((success) => {
                this.useful_documents = success.data.data
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_useful_documents = false;
            });
        },

        downloadUsefulDocuments(item, event) {
            event.preventDefault();

            let data = [];
            if (this.selected_useful_documents.length > 0) {
                data = JSON.parse(JSON.stringify(this.selected_useful_documents));
            } else if (item) {
                data.push(item);
            } else {
                data = this.useful_documents;
            }

            if (data.length > 0) {
                data.forEach((file) => {
                    this.downloadFile(file, 'PWA_DOCUMENT');
                });
            }
        },

        prepareOpenDialogPreview(file) {
            this.selected_file = file;
            if(file.file_type.type === "application/pdf") {
                this.openDialogPreviewPdf();
            } else {
                this.openDialogPreviewPicture();
            }
        },

        openDialogPreviewPicture() {
            this.dialog_preview_picture = true;
        },

        openDialogPreviewPdf() {
            this.index_page_to_display = 1;
            this.loading_my_file_pdf = true;
            this.dialog_preview_pdf = true;
            pdf.createLoadingTask(this.selected_file.path_file).promise.then(document => {
                this.total_pages = document.numPages;
                this.loading_my_file_pdf = false;
            });
        },
    }
}
</script>