<template>
    <div id="document-page">
        <toolbar-component v-bind:title="$t('document_page.title')"/>

        <v-tabs v-model="tab_selected" background-color="secondary" slider-color="primary" show-arrows
                class="default-arrows-tabs"
        >
            <v-tab href="#document-by-pro"
                   :class="(tab_selected === 'document-by-pro' ? '' : 'tab-opacity') + ' primary--text font-weight-bold '"
            >
                {{
                    $t('document_page.documents_by_pro', {pro_name: $store.getters["user_connected/user_connected"].professional_name})
                }}
            </v-tab>

            <v-tab href="#document-by-user"
                   :class="(tab_selected === 'document-by-user' ? '' : 'tab-opacity') + ' primary--text font-weight-bold '"
            >
                {{ $t('document_page.documents_by_user') }}
            </v-tab>

            <v-tab-item value="document-by-pro" class="background pa-4">
                <document-by-pro-component/>
            </v-tab-item>

            <v-tab-item value="document-by-user" class="background pa-4">
                <document-by-user-component/>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import ToolbarComponent from "@/components/Global/ToolbarComponent";
import DocumentByProComponent from "@/components/Document/DocumentByProComponent";
import DocumentByUserComponent from "@/components/Document/DocumentByUserComponent";

export default {
    name: "DocumentPage",
    components: {DocumentByUserComponent, DocumentByProComponent, ToolbarComponent},
    data() {
        return {
            tab_selected: '',
        }
    }
}
</script>